import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1;

import {get, post } from "../utils/http";
const qs = require('qs')

//查询工作日历列表
export function getWorkshift(params) {
    return get(`${aps1}/workshift/page?${qs.stringify(params)}`);
}
//查询单个日历
export function getShiftDetail(shiftId) {
    return get(`${aps1}/workshift/findByShiftId?shiftId=${shiftId}`);
}
//新增工作日历
export function addWorkshift(params) {
    return post(`${aps1}/workshift`, params);
}
//修改工作日历
export function editWorkshift(params) {
    return post(`${aps1}/workshift/updateByShiftId`, params);
}
//删除工作日历
export function delWorkshift(shiftId) {
    return get(`${aps1}/workshift/deleteByShiftId?shiftId=${shiftId}`);
}
//查询产品列表
export function getProductInfo(params) {
    return get(`${aps1}/productInfo/page?${qs.stringify(params)}`);
}
//查询单个产品详情
export function getProductDetail(id) {
    return get(`${aps1}/productInfo/findById?id=${id}`);
}
//新增产品
export function addProduct(params) {
    return post(`${aps1}/productInfo`, params);
}
//修改产品数据
export function editProduct(params) {
    return post(`${aps1}/productInfo/updateById`, params);
}
//删除产品数据
export function delProduct(id) {
    return get(`${aps1}/productInfo/deleteById?id=${id}`);
}
//批量删除产品数据
export function delProductBatch(params) {
    return post(`${aps1}/productInfo/deleteByIds`, params);
}
//导入产品数据
export function importProduct(params) {
    return post(`${aps1}/excel/importProductInfo`, params);
}
//查询产品的工序列表
export function getProcedureInfo(params) {
    return get(`${aps1}/procedureInfo/page?${qs.stringify(params)}`);
}
//通过id删除产品工序
export function delProcedure(id) {
    return get(`${aps1}/procedureInfo/deleteById?id=${id}`);
}
//查询生产资源统计
export function summaryStatistics() {
    return get(`${aps1}/productionResource/summaryStatistics`);
}
//查询生产设备列表
export function getEquipment(params) {
    return get(`${aps1}/productionEquipment/page?${qs.stringify(params)}`);
}
//通过id删除生产设备
export function delProEquipment(id) {
    return get(`${aps1}/productionEquipment/deleteById?id=${id}`);
}
//批量删除生产设备
export function delProEquipmentBatch(params) {
    return post(`${aps1}/productionEquipment/deleteByIds`, params);
}
//导入生产设备
export function importEquipment(params) {
    return post(`${aps1}/excel/importProductionEquipment`, params);
}
// 查询生产设备详情
export function getProEquipment(id) {
    return get(`${aps1}/productionEquipment/findById?id=${id}`)
}
//新增生产设备
export function addProEquipment(params) {
    return post(`${aps1}/productionEquipment`, params);
}
//编辑生产设备
export function editProEquipment(params) {
    return post(`${aps1}/productionEquipment/updateById`, params);
}
//查询检测设备列表
export function getTestEquipment(params) {
    return get(`${aps1}/testEquipment/page?${qs.stringify(params)}`);
}
//通过id删除检测设备
export function delTestEquipment(id) {
    return get(`${aps1}/testEquipment/deleteById?id=${id}`);
}
//批量删除检测设备
export function delTestEquipmentBatch(params) {
    return post(`${aps1}/testEquipment/deleteByIds`, params);
}
//导入检测设备
export function importTestEquipment(params) {
    return post(`${aps1}/excel/importTestEquipment`, params);
}
//新增检测设备
export function addTestEquipment(params) {
    return post(`${aps1}/testEquipment`, params);
}
//编辑检测设备
export function editTestEquipment(params) {
    return post(`${aps1}/testEquipment/updateById`, params);
}
//查询工作人员列表
export function getStaffList(params) {
    return get(`${aps1}/staffInfo/page?${qs.stringify(params)}`);
}
//通过id删除工作人员
export function delStaff(id) {
    return get(`${aps1}/staffInfo/deleteById?id=${id}`);
}
//批量删除工作人员
export function delStaffBatch(params) {
    return post(`${aps1}/staffInfo/deleteByIds`, params);
}
//导入工作人员
export function importStaff(params) {
    return post(`${aps1}/excel/importStaffInfo`, params);
}
//新增工作人员
export function addStaff(params) {
    return post(`${aps1}/staffInfo`, params);
}
//编辑工作人员
export function editStaff(params) {
    return post(`${aps1}/staffInfo/updateById`, params);
}
//查询工作人员详情
export function getStaffDetail(id){
    return get(`${aps1}/staffInfo/findById?id=${id}`)
}
//查询装配工艺机型列表
export function getAssemblyCraftList() {
    return get(`${aps1}/staffInfo/getAssemblyProgressList`);
}
//查询装配产品列表
export function getAssemblyProductList() {
    return get(`${aps1}/staffInfo/getAssemblyProductList`);
}
//查询辅助资源列表
export function getAssistantResources(params) {
    return get(`${aps1}/assistantResources/page?${qs.stringify(params)}`);
}
//通过id删除辅助资源
export function delAssistantResources(id) {
    return get(`${aps1}/assistantResources/deleteById?id=${id}`);
}
//批量删除辅助资源
export function delAssistantResourcesBatch(params) {
    return post(`${aps1}/assistantResources/deleteByIds`, params);
}
//导入辅助资源
export function importAssistant(params) {
    return post(`${aps1}/excel/importAssistantResourcesInfo`, params);
}
//新增辅助资源
export function addAssistant(params) {
    return post(`${aps1}/assistantResources`, params);
}
//编辑辅助资源
export function editAssistant(params) {
    return post(`${aps1}/assistantResources/updateById`, params);
}
//查询客户列表
export function getCustomer(params) {
    return get(`${aps1}/customerInfo/page?${qs.stringify(params)}`);
}
//新增客户列表
export function addCustomer(params) {
    return post(`${aps1}/customerInfo`, params);
}
//通过id删除客户
export function delCustomer(id) {
    return get(`${aps1}/customerInfo/deleteById?id=${id}`);
}
//批量删除客户
export function delCustomerBatch(params) {
    return post(`${aps1}/customerInfo/deleteByIds`, params);
}
//修改客户
export function editCustomer(params) {
    return post(`${aps1}/customerInfo/updateById`, params);
}
//导入客户
export function importCustomer(params) {
    return post(`${aps1}/excel/importCustomerInfo`, params);
}
//查询工艺列表
export function getTechnology(params) {
    return get(`${aps1}/technologyInfo/page?${qs.stringify(params)}`);
}
//通过id删除工艺
export function delTechnology(id) {
    return get(`${aps1}/technologyInfo/deleteById?id=${id}`);
}
//批量删除工艺
export function delTechnologyBatch(params) {
    return post(`${aps1}/technologyInfo/deleteByIds`, params);
}
//修改工艺
export function editTechnology(params) {
    return post(`${aps1}/technologyInfo/updateById`, params);
}
//导入工艺
export function importTechnology(params) {
    return post(`${aps1}/excel/importTechnologyInfo`, params);
}

// 获取BOM树形数据
export function getBomTree(params){
    return get(`${aps1}/productBomInfo/treeList?${qs.stringify(params)}`)
}

// 根据类型查询bom列表
export function getBomByLevel(params){
    return get(`${aps1}/productBomInfo/findByLevel?${qs.stringify(params)}`)
}

// 产品BOM数据导出
export function exportProductBom(){
    return get(`${aps1}/productBomInfo/export`)
}
// 产品BOM数据导入
export function importProductBom(params){
    return post(`${aps1}/excel/importProductBomInfo`, params)
}
// 获取装配工艺列表
export function getAssemblyList(params){
    return get(`${aps1}/assembleInfo/page?${qs.stringify(params)}`)
}
// 获取装配工艺详情
export function getAssemblyDetails(params){
    return get(`${aps1}/assembleProcessInfo/page?${qs.stringify(params)}`)
}
// 装配工艺导入
export function assembleProcess(params){
    return post(`${aps1}/excel/importAssembleProcessData`,params)
}
// 数据管理--库存列表
export function getInventoryInfoData(page, size) {
    return get(`${aps1}/inventoryInfo/page?page=${page}&size=${size}`)
}

// 数据管理--库存列表--搜索
export function searchInventoryInfoData(page, size, searchValue) {
    return get(`${aps1}/inventoryInfo/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入库存列表
export function importInventoryInfo(params) {
    return post(`${aps1}/excel/importInventoryInfo`, params)
}

// 导出库存列表
export function exportInventoryInfo(params) {
    return post(`${aps1}/inventoryInfo/export`, params)
}

// 库存管理--钉钉同步
export function dingdingImport(page, size){
    return get(`${aps1}/inventoryInfo/importInventory?page=${page}&size=${size}`)
} 

// 查询抽检设置
export function getsampling() {
    return get(`${aps1}/samplingQuantitySetting/list`)
}
// 修改抽检设置
export function updateSampling(params) {
    return post(`${aps1}/samplingQuantitySetting/batchUpdate`, params)
}