<template>
  <div>
    <div class="content">
      <div class="contentTop contentTopOne">
        <div class="contentTopTitle">库存管理</div>
        <div class="contentTopRight">
          <div class="yida" @click="yida" v-premiSub="'库存管理_宜搭导入'">
            <img src="@/assets/images/production/dingding.png" alt="">
            <span>钉钉同步</span>
          </div>
          <el-tooltip v-premiSub="'库存管理_导出'" class="item" effect="dark" content="导出" placement="top">
            <img src="@/assets/images/production/export.png" alt="" @click="exportDialog">
          </el-tooltip>
          <el-tooltip v-premiSub="'库存管理_导入'" class="item" effect="dark" content="导入" placement="top">
            <img src="@/assets/images/production/leading-in.png" @click="importDialog" alt="">
          </el-tooltip>
          <el-input v-premiSub="'库存管理_搜索'" placeholder="请输入关键词" suffix-icon="el-icon-search" size="small"
            v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="65">
        </el-table-column>
        <el-table-column prop="showId" label="序号" fixed="left" width="80" />
        <el-table-column prop="inventoryType" label="库存类型" width="150" />
        <el-table-column prop="orderNo" label="订单号" width="150" />
        <el-table-column prop="materialNo" label="物料编码" width="150" />
        <el-table-column prop="materialName" label="物料名称" width="180" />
        <el-table-column prop="partCode" label="图号" width="200" />
        <el-table-column prop="versionCode" label="版本号" width="150" />
        <el-table-column prop="rawMaterial" label="材质" width="150" />
        <el-table-column prop="model" label="规格型号" width="250" />
        <el-table-column prop="length" label="长(mm)" width="150" />
        <el-table-column prop="width" label="宽(mm)" width="150" />
        <el-table-column prop="height" label="高(mm)" width="150" />
        <el-table-column prop="quantityUnit" label="单位" width="150" />
        <el-table-column prop="quantity" label="库存数量" width="150" />
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
    <resultDialog :name="name" :fileName="fileName" :resultMsg="resultMsg" :resultVisible="resultVisible"
      @reUpload="reUpload" :type="type" @update:resultVisible="closeFn" />
    <ImportDialog v-if="importVisible" :visible.sync="importVisible" templateName="库存管理导入模板"
      templateParam="inventoryInfo" :apis="apis" @success="handleProduction('update')" />
  </div>
</template>

<script>
const resultDialog = () => import("@/components/upload/resultDialog.vue")
import { getInventoryInfoData, searchInventoryInfoData, dingdingImport, importInventoryInfo, exportInventoryInfo } from '@/api/data.js'
import ImportDialog from '@/components/dialog/ImportDialog.vue'
import { getDay } from "@/utils/date.js"
export default {
  name: "inventory_management",
  components: {
    resultDialog,
    ImportDialog
  },
  data() {
    return {
      apis: {
        import: importInventoryInfo,//导入请求的接口
        // importTemplate:excelTemplate,//下载模板接口
      },
      importVisible: false,
      name: '',
      fileName: '',
      resultVisible: false,
      resultMsg: '',
      type: true,
      status: false,
      tableData: [],
      currentPage1: 1,
      total: 0,  //总条数，用于分组组件
      input2: '',
      tempData: [],
      tempData2: [],
      limitNum: 1, //文件上传个数限制
      fileList: [], //文件列表
      pageChange: 10,   //当前页面多少条工单
      tempTotal: 0,  //总条数，但与分页组件无关
      searchStatus: false,
      searchData: [],
      multipleSelection: [],
      isShow: false,
      isShowSplit: false,
      tempArr: [],
    }
  },
  methods: {
    importDialog() {
      this.importVisible = true
    },
    async exportDialog() {
      let ids = []
      this.multipleSelection.forEach(v => ids.push(v.id))
      const res = await exportInventoryInfo(ids)
      this.exportFn(res, '库存管理导出数据')
    },
    exportFn(res, val) {
      this.filename = `${val} ${getDay()}.xlsx`
      this.url = window.URL.createObjectURL(res)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = this.url
      link.setAttribute('download', this.filename)
      document.documentElement.appendChild(link)
      link.click()
      document.documentElement.removeChild(link)
      this.$message.success('导出成功！')
    },
    handleProduction() {
      this.renovate()
      this.importVisible = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.tempArr = val.map(v => v.id)
    },
    // 钉钉导入
    async yida() {
      // 约定一次最多100条
      const res = await dingdingImport(1, 100)
      if (res.code === 0) {
        this.$message.success('钉钉导入成功！')
      } else {
        this.$message.error({
          showClose: true,
          message:res.msg,
          duration:0
        })
      }
      await this.renovate()
    },
    // 组件一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.status) {
        this.tempData = this.tempData2.filter((v, i) => i < val)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else {
        const res = await getInventoryInfoData(1, val)
        if (res.code === 0) {
          const { records } = res.data
          this.tableData = records
          this.tempData = this.tableData
        }
      }
      this.currentPage1 = 1
    },
    // 选择页数
    async handleCurrentChange(val) {
      if (this.status) {
        // 如果数据经过筛选
        this.tempData = this.tempData2.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getInventoryInfoData(val, this.pageChange)
        if (res.code === 0) {
          const { records } = res.data
          this.tableData = records
          this.tempData = records
        }
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      const res = await searchInventoryInfoData(1, this.tempTotal, this.input2)
      // 搜索后的总条数
      this.searchData = res.data.records
      this.searchStatus = true
      // this.pageChange = 10
      this.currentPage1 = 1
      this.tempData = this.searchData.filter((v, i) => i < this.pageChange)
      this.total = this.searchData.length
    },
    async renovate() {
      // 赋值数据
      const res = await getInventoryInfoData(1, 10)
      const { records, total } = res.data
      this.tableData = this.tempData = records
      this.total = this.tempTotal = total
      this.pageChange = 10
      this.currentPage1 = 1
      this.status = this.searchStatus = false
      this.input2 = ''
    },
    closeFn() {
      this.resultVisible = false
    },
    reUpload() {
      let temp = document.querySelectorAll('.el-upload')
      temp[0].click()
    }
  },
  async created() {
    await this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>
